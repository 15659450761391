import React from "react";
import { Link, useLocation } from "react-router-dom";
import Linkedin from "common/images/LinkedInLogo.svg";
import styles from "./Footer.module.css";
import { Divider, Grid, useMediaQuery } from "@mui/material";
import ElevadeLogo from "common/images/Elevade-Logo-White.svg";
import IconExternalLinkWhite from "common/images/Icon-ExternalLinkWhite.svg";

const Footer = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:900px)");

  const email = "mailto:elevade_support@ade.aero";

  return (
    // <Fragment>
    location.pathname === "/" && (
      <footer
        id="footer-main"
        className={`${styles.footer}`}
        style={{ paddingLeft: isMobile && location.pathname !== "/fleet" ? "100px" : "" }}
      >
        <Divider orientation="horizontal" light variant="middle" />
        <Grid container columns={5}>
          <Grid item xs={2.5} sm={2.5} md={2} lg={1} xl={1}>
            <span className={styles.textSmallBold} style={{ color: "#fff" }}>
              Solutions
            </span>
            <ul className={`${styles.textSmall} ${styles.ulFooter}`} style={{ color: "#fff" }}>
              <li>
                <Link to="https://airasia.elevade.io/fleet" target="blank_" style={{ color: "#ffffff" }}>
                  FLEET <img src={IconExternalLinkWhite} alt="" style={{ marginTop: "-2px", marginLeft: "4px" }} />
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "https://ade.aero/aerotrade/marketplace" }}
                  target="blank_"
                  style={{ color: "#ffffff" }}
                >
                  AEROTRADE <img src={IconExternalLinkWhite} alt="" style={{ marginTop: "-2px", marginLeft: "4px" }} />
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={2.5} sm={2.5} md={2} lg={1} xl={1}>
            <span className={styles.textSmallBold} style={{ color: "#fff" }}>
              Company
            </span>
            <ul className={`${styles.textSmall} ${styles.ulFooter}`} style={{ color: "#fff" }}>
              <li>
                <Link
                  to={{ pathname: "https://ade.aero/about-us/who-we-are" }}
                  target="blank_"
                  style={{ color: "#ffffff" }}
                >
                  About Us <img src={IconExternalLinkWhite} alt="" style={{ marginTop: "-2px", marginLeft: "4px" }} />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    window.location.href = email;
                    e.preventDefault();
                  }}
                  style={{ color: "#ffffff" }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={5} sm={5} md={1} lg={1} xl={1} sx={{ paddingTop: isMobile ? "32px" : "" }}>
            <span className={styles.textSmallBold} style={{ color: "#fff" }}>
              Quick Links
            </span>
            <ul className={`${styles.textSmall} ${styles.ulFooter}`} style={{ color: "#fff" }}>
              <li>
                <Link to={{ pathname: "https://ade.aero" }} target="blank_" style={{ color: "#ffffff" }}>
                  ade.aero <img src={IconExternalLinkWhite} alt="" style={{ marginTop: "-2px", marginLeft: "4px" }} />
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={0} sm={0} md={0} lg={1} xl={1}></Grid>
          <Grid item xs={5} sm={5} md={2} lg={1} xl={1}>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "" : "column",
                alignItems: "center",
                gap: "24px",
                paddingTop: isMobile ? "40px" : "0px",
              }}
            >
              <img src={ElevadeLogo} alt="Elevade Logo" width={isMobile ? 100 : 130} />
              <div className={styles.text} style={{ margin: isMobile ? "0 20px" : "0px" }}>
                Follow us on:
                <Link
                  to={{ pathname: "https://www.linkedin.com/company/ade-aero/mycompany/" }}
                  target="blank_"
                  style={{ color: "#ffffff" }}
                >
                  <img src={Linkedin} alt="LinkedIn" style={{ padding: "0 10px" }} />
                </Link>
              </div>
            </div>
            <div className={styles.text}>
              &copy; {new Date().getFullYear()} All rights reserved by
              <br />
              Asia Digital Engineering Sdn Bhd
            </div>
          </Grid>
        </Grid>
      </footer>
    )
    // </Fragment>
  );
};

export default Footer;
