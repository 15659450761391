// src/redux/rootReducer.js
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import sidebarReducer from "./reducers/sidebarReducer";
import userReducer from "./reducers/userReducer";
import userSessionReducer from "./reducers/userSessionReducer";
import tokenReducer from "./reducers/tokenReducer";
import storageSession from "redux-persist/lib/storage/session";

const persistConfigSession = {
  key: "userSession",
  storage: storageSession,
};

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  user: userReducer,
  token: tokenReducer,
  userSession: persistReducer(persistConfigSession, userSessionReducer),
});

export default rootReducer;
