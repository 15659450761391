import { SET_MESSAGE_SESSION, SET_SHOW_MESSAGE_BAR } from "./actionTypes";

export const setShowMessageBar = (bool) => ({
  type: SET_SHOW_MESSAGE_BAR,
  payload: bool,
});

export const setMessageSession = (message) => ({
  type: SET_MESSAGE_SESSION,
  payload: message,
});
