import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styles from "./DialogBox.module.css";
import { Grid, ClickAwayListener } from "@mui/material";
import IconClose from "common/images/Icon-Close.svg";
import MultiSelect from "../multiSelect/MultiSelect";
import { formatNotificationDateTime, makeAPIRequest } from "common/helpers/Helpers";
import { MODULES } from "common/constant";

const DialogBox = ({ ...props }) => {
  const navigate = useNavigate();

  const [isActive, setIsActive] = useState(1);
  const [isData, setIsData] = useState([]);
  const [isFilteredData, setIsFilteredData] = useState(isData);
  const [moduleValue, setModuleValue] = useState([]);
  const [isAllModuleFocus, setIsAllModuleFocus] = useState(false);

  useEffect(() => {
    let tempData = isData;
    if (isActive === 2) {
      tempData = isData.filter((e) => e.read === false);
    }

    if (moduleValue.length > 0) {
      tempData = tempData.filter((item) =>
        moduleValue.find((module) => module.value.toLocaleUpperCase() === item.module.toLocaleUpperCase()),
      );
    }
    setIsFilteredData(tempData);
  }, [isData, isActive, moduleValue]);

  const DotRed = (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
      <circle cx="3" cy="3" r="3" fill="#E95048" />
    </svg>
  );

  function getTotalRead(bool) {
    return isFilteredData.filter((e) => e.read === bool).length;
  }

  async function handleNotificationClick(notification) {
    if (!notification.read) {
      try {
        const result = await makeAPIRequest("put", `api/notification/markAsRead/${notification.id}`);
        await getNotification();
        setTimeout(() => {
          props.setIsCountNoti(getTotalRead(false));
        }, 500);
      } catch (error) {
        console.log("Error when marking notification as read: ", error);
      }
    }

    if (notification.redirectUrl) {
      window.location.href = notification.redirectUrl;
    }
  }

  async function handleMarkAllAsRead() {
    try {
      const result = await makeAPIRequest("put", "api/notification/markAllAsRead");

      await getNotification();
      setTimeout(() => {
        props.setIsCountNoti(getTotalRead(false));
      }, 500);
    } catch (error) {
      console.log("Error when marking all as read: ", error);
    }
  }

  async function getNotification() {
    try {
      const result = await makeAPIRequest("post", "api/notification/getByFilter", {});

      const formattedResult = result.map((item) => ({
        id: item.id,
        module: item.module,
        title: item.title,
        content: item.message,
        datetime: item.createdAt,
        read: item.read,
        redirectUrl: item.redirectUrl,
        month: new Date(item.createdAt).getMonth(),
        year: new Date(item.createdAt).getFullYear(),
        dateString: new Date(item.createdAt)
          .toLocaleString("default", { month: "long", year: "numeric" })
          .toLocaleUpperCase(),
      }));

      setIsData(formattedResult);
    } catch (error) {
      console.log("Error while fetching notification: ", error);
    }
  }

  useEffect(() => {
    getNotification();
  }, []);

  return (
    props.open && (
      <ClickAwayListener onClickAway={() => props.setOpen(false)}>
        <div className={styles.container}>
          <Grid container columns={2} className={styles.gridContainer}>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={styles.title}>
              Notifications
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={styles.close}>
              <img
                src={IconClose}
                alt="icon-close"
                height={10}
                className={styles.pointer}
                onClick={() => props.setOpen(false)}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={styles.gridSwitcher}>
              <div className={styles.containerSwitcher}>
                <div
                  className={`${styles.swicth} ${isActive === 1 && styles.switchActive}`}
                  onClick={() => setIsActive(1)}
                >
                  All
                </div>
                <div
                  className={`${styles.swicth} ${isActive === 2 && styles.switchActive}`}
                  onClick={() => setIsActive(2)}
                >
                  Unread
                  {getTotalRead(false) > 0 && <div className={styles.unreadTip}>{getTotalRead(false)}</div>}
                </div>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={styles.gridSwitcher}>
              <MultiSelect
                onOpen={() => {
                  setIsAllModuleFocus(true);
                }}
                onClose={() => {
                  setIsAllModuleFocus(false);
                }}
                placeholder={moduleValue.length > 0 ? "" : isAllModuleFocus ? "Select / Search..." : "All Modules"}
                options={Object.keys(MODULES).map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(_, second) => {
                  setModuleValue(second);
                }}
                value={moduleValue}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              className={styles.markAllAsRead}
              onClick={() => {
                handleMarkAllAsRead();
              }}
            >
              Mark all as read
            </Grid>
          </Grid>
          <div className={styles.divLine}></div>
          <div className={styles.contentBody}>
            {isFilteredData.length > 0 &&
              isFilteredData
                .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
                .map((item, index) => {
                  return (
                    <>
                      {(index === 0 || isFilteredData[index].dateString !== isFilteredData[index - 1].dateString) && (
                        <div className={styles.month}>{item.dateString}</div>
                      )}
                      <div
                        className={styles.notification}
                        key={"notification-" + index}
                        onClick={() => {
                          handleNotificationClick(item);
                        }}
                      >
                        <Grid container columns={2}>
                          <Grid item xs={1.8} sm={1.8} md={1.8} lg={1.8} xl={1.8} className={styles.title}>
                            {item.title}
                          </Grid>
                          <Grid item xs={0.2} sm={0.2} md={0.2} lg={0.2} xl={0.2}>
                            {!item.read && <div className={styles.dotRed}>{DotRed}</div>}
                          </Grid>
                          {item.content !== "" && (
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                              <div className={styles.notificationMsg}>{item.content}</div>
                            </Grid>
                          )}
                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <div className={`${styles.divProduct}`}>{item.module.toLocaleUpperCase()}</div>
                          </Grid>
                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={styles.notificationTime}>
                            {formatNotificationDateTime(item.datetime)}
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  );
                })}
          </div>
          <div
            className={styles.viewAll}
            onClick={() => {
              props.setOpen(false);
              navigate("/main/notifications");
            }}
          >
            <span className={styles.pointer}>View All</span>
          </div>
        </div>
      </ClickAwayListener>
    )
  );
};

export default DialogBox;
