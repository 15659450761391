import React, { useEffect, useState } from "react";
import styles from "./Main.module.css";
import lottie from "lottie-web";
import AvatarInterimPostLogin from "common/images/AvatarInterimPostLogin.json";
import { Grid } from "@mui/material";
import ActionableItems from "./ActionableItems";
import QuickAccess from "./QuickAccess";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Main = () => {
  const isOpen = useSelector((state) => state.sidebar.isOpen);
  const { userDetails } = useSelector((state) => state.user);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    var anime = lottie.loadAnimation({
      container: document.getElementById("lottieMove"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: AvatarInterimPostLogin,
    });
    return () => anime.destroy();
  }, []);

  const date = new Date();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const formattedDate = date.toLocaleDateString("en-US", options);

  return (
    <div className={`${styles.container}`}>
      <Helmet>
        <title>ELEVADE - Main</title>
      </Helmet>
      <div
        className={`${styles.content} ${isOpen ? styles.contentSidebarOpen : ""} ${
          isVisible ? styles.paddingTop40 : ""
        }`}
      >
        <Grid container columns={4}>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={styles.headerContainer}>
            <div className={styles.avatar}>
              <div id="lottieMove"></div>
            </div>
            <div className={styles.welcome}>
              <span className={styles.hello}>Hello</span>{" "}
              <span className={styles.username}>{userDetails.fullName}!</span>
              <br />
              <span className={styles.welcomeText}>Excited to explore ELEVADE's features? Let's get started!</span>
            </div>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={styles.date}>
            {formattedDate}
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={styles.actionableItems}>
            <ActionableItems />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={styles.actionableItems}>
            <QuickAccess />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Main;
