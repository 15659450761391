import React, { useEffect } from "react";
import { COOKIES } from "common/constant";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Outlet, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import _ from "lodash";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ROUTES } from "common/constant/routes";

export default function PrivateRoutes() {
  const navigate = useNavigate();

  const token = Cookies.get("elevadeToken");
  const { userProfile, userDetails } = useSelector((state) => state.user);
  const userId = userProfile === null || !userDetails ? false : true;

  return (
    <>
      {!userDetails ? (
        <div>
          <Modal open={true}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                pt: 2,
                px: 4,
                pb: 3,
              }}
            >
              <h2>You don't have permission</h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(ROUTES.LOGOUT, { replace: true });
                  }}
                >
                  Back
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      ) : !userId || _.isEmpty(token) ? (
        <Navigate to="/login" />
      ) : (
        <Outlet />
      )}
      ;
    </>
  );
}
