import React, { Fragment } from "react";
import "./App.css";
import "@ade/global-component-web/dist/index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./common/layouts/header/Header";
import Sidebar from "common/layouts/sidebar/Sidebar";
import MessageBar from "./pages/main/MessageBar";
import NotificationCard from "./pages/main/NotificationCard";
import Main from "./pages/main/Main";
import ActionableItems from "pages/actionableItems/ActionableItems";
import Notifications from "pages/notifications/Notifications";
import Callback from "pages/callback/Callback";
import RedirectLogin from "pages/login/Login";
import ProtectedRoute from "common/layouts/route/ProtectedRoute";
import Error403 from "pages/error/403";
import LandingPage from "pages/landingPage/LandingPage";
import { useSelector } from "react-redux";
import Footer from "common/layouts/footer/Footer";
import Logout from "pages/logout/Logout";

function App() {
  const userToken = useSelector((state) => state.token.token);
  console.log("App env: ", process.env.REACT_APP_APPENV);

  return (
    <div className="App">
      <Fragment>
        <Router>
          <Sidebar />
          <Header />
          {userToken && <MessageBar />}
          <Routes>
            <Route exact path="/callback" element={<Callback />} />
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/login" element={<RedirectLogin />} />
            <Route exact path="/logout" element={<Logout />} />
            {/* <Route exact path="/main" element={<LandingPage />} /> */}
            <Route exact path="/main" element={<ProtectedRoute />}>
              <Route exact path="/main" element={<Main />} />
              <Route exact path="/main/actionable-items" element={<ActionableItems />} />
              <Route exact path="/main/notifications" element={<Notifications />} />
            </Route>
            <Route exact path="/403" element={<Error403 />} />
            {/* <Route path="*" element={<Navigate to="/login" />} /> */}
          </Routes>
          <Footer />
        </Router>
      </Fragment>
    </div>
  );
}

export default App;
