import React, { useState, useEffect } from "react";
import styles from "./ActionableItems.module.css";
import { Grid } from "@mui/material";
import IconActionableItems from "common/images/Icon-ActionableItems.svg";
import IconRecentActivity from "common/images/Icon-Flash.svg";
import IconProductPeople from "common/images/Icon-ProductPeople.svg";
import IconProductDocument from "common/images/Icon-ProductDocument.svg";
import IconProductFleet from "common/images/Icon-ProductFleet.svg";
import IconProductLearning from "common/images/Icon-ProductLearning.svg";
import { ReactComponent as IconCheckList } from "common/images/Icon-Check-List.svg";
import MUIAvatar from "common/components/avatar/MUIAvatar";
import { calculateDifferenceAndFormat, makeAPIRequest } from "common/helpers/Helpers";
import { Link } from "react-router-dom";
import Spacer from "common/components/spacer/Spacer";

const ActionableItems = () => {
  const [isActionableData, setIsActionableData] = useState([]);
  const [isRecentActivityData, setIsRecentActivityData] = useState([]);

  function GetProductIcon(product) {
    if (product === "People") return <img src={IconProductPeople} alt="icon-people" />;
    else if (product === "Document") return <img src={IconProductDocument} alt="icon-document" />;
    else if (product === "Fleet") return <img src={IconProductFleet} alt="icon-fleet" />;
    else if (product === "Learning") return <img src={IconProductLearning} alt="icon-learning" />;
  }

  function sortByDateTime(arr, key) {
    return arr.sort((a, b) => {
      let dateA = new Date(a[key]);
      let dateB = new Date(b[key]);

      return dateB - dateA;
    });
  }

  useEffect(() => {
    const sortedData = sortByDateTime(isActionableData, "datetime");
    setIsActionableData(sortedData);

    const sortedRecentActivity = sortByDateTime(isRecentActivityData, "datetime:");
    setIsRecentActivityData(sortedRecentActivity);
  }, [isActionableData]);

  async function getNotification() {
    try {
      const result = await makeAPIRequest("post", "api/notification/getByFilter", {
        methods: ["Actionable_Item"],
      });

      const formattedResult = result.map((item) => ({
        userFullName: item.createdBy,
        cardTitle: item.title,
        action: item.message,
        product: item.module,
        datetime: item.createdAt,
        redirectUrl: item.redirectUrl,
        markAsDone: item.markAsDone,
      }));

      setIsActionableData(formattedResult);
    } catch (error) {
      console.log("Error while fetching notification: ", error);
    }
  }

  async function getRecentActivity() {
    try {
      const result = await makeAPIRequest("post", "api/activity/getByFilter", {});
      const formatedResult = result.map((item) => ({
        cardTitle: item.pageName,
        action: "You viewed",
        product: item.module,
        datetime: item.createdAt,
        redirectUrl: item.redirectUrl,
      }));

      setIsRecentActivityData(formatedResult);
    } catch (error) {
      console.log("Error while fetching recent activity: ", error);
    }
  }

  useEffect(() => {
    getRecentActivity();
    getNotification();
  }, []);

  const DotRed = (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
      <circle cx="3" cy="3" r="3" fill="#E95048" />
    </svg>
  );

  return (
    <div className={styles.container}>
      <Grid container columns={4} className={styles.gridContainer}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={styles.contentTitle}>
          <div className={styles.divImg}>
            <img src={IconActionableItems} alt="icon-actionable" height={17} />
          </div>
          <div className={styles.divTitle}>Actionable Items</div>
          {isActionableData.length > 0 && <div className={styles.noti}>{isActionableData.length}</div>}
          <Link to="/main/actionable-items">
            <span className={`${styles.contentViewAll} ${styles.pointer}`}>View All</span>
          </Link>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={styles.contentTitle}>
          <div className={styles.divImg}>
            <img src={IconRecentActivity} alt="icon-actionable" height={17} />
          </div>
          <div className={styles.divTitle}>Activities</div>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={`${styles.contentCard} ${styles.padRight44}`}>
          {isActionableData.length === 0 && (
            <>
              <Spacer height={16} />
              <Grid container className={styles.placeholderEmptyList}>
                <Grid item xs={3}>
                  <IconCheckList min={150} />
                </Grid>
                <Grid item>
                  <span>
                    Your task list is currently empty. This section will
                    <br />
                    display any upcoming tasks requiring your attention.
                  </span>
                </Grid>
              </Grid>
            </>
          )}
          {isActionableData
            .filter((item) => item.markAsDone === false)
            .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
            .slice(0, 3)
            .map((item, index) => {
              return (
                <div
                  className={styles.taskCard}
                  key={index}
                  onClick={() => {
                    window.location.href = item.redirectUrl;
                  }}
                >
                  <div>
                    {item.userFullName ? (
                      <MUIAvatar name={item.userFullName} width="24px" height="24px" fontSize="10px" />
                    ) : (
                      <div className={styles.tackCardImg}>{GetProductIcon(item.product)}</div>
                    )}
                  </div>
                  <div>
                    {item.userFullName ? (
                      <>
                        <span className={styles.bold}>{item.userFullName}</span>{" "}
                        <span className={styles.cardAction}>{item.action}</span>
                      </>
                    ) : item.cardTitle ? (
                      <>
                        <span className={styles.bold}>{item.cardTitle}</span>{" "}
                        <span className={styles.cardAction}>{item.action}</span>
                      </>
                    ) : (
                      ""
                    )}
                    <div className={styles.divProduct}>{item.product}</div>
                    <div className={styles.dateTimeDifference}>{calculateDifferenceAndFormat(item.datetime)} ago</div>
                    {item.markAsDone === false && <div className={styles.dotRed}>{DotRed}</div>}
                  </div>
                </div>
              );
            })}
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={`${styles.contentCard}`}>
          {isRecentActivityData.length === 0 && (
            <>
              <Spacer height={16} />
              <Grid container className={styles.placeholderEmptyList}>
                <Grid item xs={3}>
                  <IconCheckList min={150} />
                </Grid>
                <Grid item>
                  <span>
                    Your recent activity will appear here as you start
                    <br />
                    using the application
                  </span>
                </Grid>
              </Grid>
            </>
          )}

          {isRecentActivityData
            .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
            .slice(0, 3)
            .map((item, index) => {
              return (
                <div
                  className={styles.taskCard}
                  key={index}
                  onClick={() => {
                    window.location.href = item.redirectUrl;
                  }}
                >
                  <div>
                    {item.userFullName ? (
                      <MUIAvatar name={item.userFullName} width="24px" height="24px" fontSize="10px" />
                    ) : (
                      <div className={styles.tackCardImg}>{GetProductIcon(item.product)}</div>
                    )}
                  </div>
                  <div>
                    {item.action ? (
                      <>
                        <span className={styles.cardAction}>{item.action}</span>{" "}
                        <span className={styles.bold}>{item.cardTitle}</span>
                      </>
                    ) : (
                      ""
                    )}
                    <div className={styles.divProduct}>{item.product}</div>
                    <div className={styles.dateTimeDifference}>{calculateDifferenceAndFormat(item.datetime)} ago</div>
                  </div>
                </div>
              );
            })}
        </Grid>
      </Grid>
    </div>
  );
};

export default ActionableItems;
