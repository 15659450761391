import React, { useEffect, useState } from "react";
import styles from "./MessageBar.module.css";
import IconUpdateimg from "common/images/Icon-Update.svg";
import IconWarning from "common/images/Icon-Warning.svg";
import IconImportant from "common/images/Icon-Important.svg";
import IconError from "common/images/Icon-Error.svg";
import IconChevronDisabled from "common/images/chevron-left.svg";
import IconChevronActive from "common/images/chevron-right.svg";
import IconChevronActiveBlack from "common/images/chevron-right-black.svg";
import IconClose from "common/images/Icon-CloseWhite.svg";
import IconLine from "common/images/Line 5075.svg";
import { Fade } from "@mui/material";
import { makeAPIRequest } from "common/helpers/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { setMessageSession, setShowMessageBar } from "common/redux/actions/userSessionAction";

const MessageBar = ({ ...props }) => {
  const dispatch = useDispatch();

  const showMessageBar = useSelector((state) => state.userSession.showMessageBar);
  const messageSession = useSelector((state) => state.userSession.messageSession);
  const userProfile = useSelector((state) => state.user.userProfile);

  const [isId, setIsId] = useState(0);
  const [isMessage, setIsMessage] = useState([]);
  const [allMessage, setAllMessage] = useState([]);

  const currentMessage = isMessage[isId];

  const totalUndone = isMessage.filter((item) => item.markAsDone === false).length;

  function handleNext() {
    let currentId = isId;
    let nextId = currentId + 1;
    //check condition if equal to isMessage.length
    if (currentId === isMessage.length - 1) {
      setIsId(currentId);
    } else {
      setIsId(nextId);
    }
  }
  function handlePrev() {
    let currentId = isId;
    let nextId = currentId - 1;
    //check condition if equal to 0
    if (currentId === 0) {
      setIsId(currentId);
    } else {
      setIsId(nextId);
    }
  }

  function getIcon(arrow) {
    if (isId === 0 && arrow === "previous") {
      return (
        <>
          <img src={IconChevronDisabled} alt="icon-non-actionable-left" height={16} className={styles.disableCursor} />
        </>
      );
    } else if (isMessage.length === 1 && arrow === "next") {
      return (
        <img
          src={IconChevronDisabled}
          alt="icon-non-actionable-next"
          height={16}
          className={styles.rotate180DisableCursor}
        />
      );
    } else if (
      isId === 0 &&
      arrow === "next" &&
      (currentMessage?.severity === "Important" || currentMessage?.severity === "Warning")
    ) {
      return <img src={IconChevronActiveBlack} alt="icon-actionable-next" height={16} />;
    } else if (isId === 0 && arrow === "next") {
      return <img src={IconChevronActive} alt="icon-actionable-next" height={16} />;
    } else if (
      isId <= isMessage.length - 1 &&
      arrow === "previous" &&
      (currentMessage?.severity === "Important" || currentMessage?.severity === "Warning")
    ) {
      return <img src={IconChevronActiveBlack} alt="icon-actionable-left" height={16} className={styles.rotate180} />;
    } else if (isId <= isMessage.length - 1 && arrow === "previous") {
      return <img src={IconChevronActive} alt="icon-actionable-left" height={16} className={styles.rotate180} />;
    } else if (
      isId <= isMessage.length - 2 &&
      arrow === "next" &&
      (currentMessage?.severity === "Important" || currentMessage?.severity === "Warning")
    ) {
      return <img src={IconChevronActiveBlack} alt="icon-actionable-next" height={16} />;
    } else if (isId <= isMessage.length - 2 && arrow === "next") {
      return <img src={IconChevronActive} alt="icon-actionable-next" height={16} />;
    } else if (isId <= isMessage.length - 1 && arrow === "next") {
      return (
        <img
          src={IconChevronDisabled}
          alt="icon-non-actionable-next"
          height={16}
          className={styles.rotate180DisableCursor}
        />
      );
    }
  }

  async function getSystemMessage() {
    try {
      const result = await makeAPIRequest("post", "api/notification/getByFilter", {
        methods: ["Message_Bar"],
      });

      const data = result
        .filter((item) => {
          return item.markAsDone === false;
        })
        .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
        .map((item) => ({
          data: item.message,
          url: item.redirectUrl,
          severity: item.severity,
          urlTitle: item.title,
          markAsDone: item.markAsDone,
          id: item.id,
        }));

      const test = messageSession;

      result.forEach((item) => {
        if (!test[item.id]) {
          test[item.id] = {
            messageClose: false,
          };
        }
      });

      setIsMessage(data.slice(0, 3));
      setAllMessage(data);

      dispatch(setMessageSession(test));
    } catch (error) {
      console.log("Error while fetching system message: ", error);
    }
  }

  useEffect(() => {
    getSystemMessage();
  }, [userProfile]);

  useEffect(() => {
    const tempMessageSession = {};

    Object.keys(messageSession).forEach((item) => {
      if (allMessage.find((message) => message.id === item)) {
        tempMessageSession[item] = messageSession[item];
      }
    });

    const closeMessageBar =
      Object.values(tempMessageSession).filter((item) => item.messageClose === false).length === 0;

    if (closeMessageBar) {
      dispatch(setShowMessageBar(false));
    } else {
      dispatch(setShowMessageBar(true));
    }
  }, [messageSession, allMessage.length]);

  return (
    showMessageBar &&
    totalUndone > 0 && (
      <Fade in={showMessageBar}>
        <div
          className={`${styles.container} ${currentMessage?.severity === "Warning" && styles.containerWarning} 
           ${currentMessage?.severity === "Alert" && styles.containerError}
           ${currentMessage?.severity === "Important" && styles.containerImportant}
          `}
          id="messageBar"
        >
          <div className={styles.containerIcon}>
            <div className={styles.IconUpdate}>
              {currentMessage?.severity === "Normal" && <img src={IconUpdateimg} alt="icon-actionable" height={16} />}
              {currentMessage?.severity === "Warning" && <img src={IconWarning} alt="icon-actionable" height={16} />}
              {currentMessage?.severity === "Important" && (
                <img src={IconImportant} alt="icon-actionable" height={16} />
              )}
              {currentMessage?.severity === "Alert" && <img src={IconError} alt="icon-actionable" height={16} />}
            </div>
            <div className={styles.textUpdateContainer}>
              {currentMessage?.data}
              {currentMessage?.url !== "" && (
                <a href={currentMessage?.url} className={styles.textHyperlinkContainer}>
                  {currentMessage?.urlTitle}
                </a>
              )}
            </div>
            <div className={styles.containerControl}>
              <div className={styles.containerPagination}>
                <div className={styles.paginationIcon} onClick={() => handlePrev()}>
                  {getIcon("previous")}
                </div>
                <div className={styles.textControlContainer}>
                  {isId + 1}/{Math.min(isMessage.length, 3)}
                </div>
                <div className={styles.paginationIcon} onClick={() => handleNext()}>
                  {getIcon("next")}
                </div>
              </div>
              <img src={IconLine} alt="icon-actionable" className={styles.lineIcon} />
              <span
                style={{
                  marginTop: 2,
                  color:
                    isMessage[isId]?.severity === "Important" || isMessage[isId]?.severity === "Warning"
                      ? "#333"
                      : "#F7F7F7",
                }}
                className={styles.closeIcon}
                onClick={() => {
                  const test = messageSession;
                  test[currentMessage.id] = {
                    messageClose: true,
                  };

                  dispatch(setMessageSession({ ...test }));
                  const currentAllMessage = allMessage
                    .filter((item) => test[item.id]?.messageClose === false)
                    .slice(0, 3);
                  setIsMessage(currentAllMessage);
                  const currentIndex = Math.min(isId, currentAllMessage.length - 1);
                  setIsId(currentIndex);
                }}
              >
                X
              </span>
            </div>
          </div>
        </div>
      </Fade>
    )
  );
};

export default MessageBar;
