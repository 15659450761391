import { COOKIES } from "common/constant";
import { setAuthToken } from "common/redux/actions/authToken";
import { logout } from "common/redux/actions/userAction";
import { setMessageSession } from "common/redux/actions/userSessionAction";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteAllCookies } from "common/helpers/Helpers";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    Cookies.remove(COOKIES.ELEVADE_TOKEN);
    deleteAllCookies();
    dispatch(logout());
    dispatch(setMessageSession({}));
    dispatch(setAuthToken(null));
    window.location.replace("/");
  }, []);
};

export default Logout;
