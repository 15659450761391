// import SidebarElevadeDashboardIcon from "common/images/sidebar/SidebarElevadeDashboard-Icon.svg";
import SidebarElevadeDashboardIconActive from "common/images/sidebar/SidebarElevadeDashboard-IconActive.svg";
import SidebarElevadeFleetIcon from "common/images/sidebar/SidebarElevadeFleet-Icon.svg";
import SidebarElevadePeopleIcon from "common/images/sidebar/SidebarElevadePeople-Icon.svg";
import SidebarElevadeEducadeIcon from "common/images/sidebar/SidebarElevadeEducade-Icon.svg";
import SidebarElevadeDocumentIcon from "common/images/sidebar/SidebarElevadeDocument-Icon.svg";
import SidebarEntityIcon from "common/images/sidebar/SidebarEntity-Icon.svg";
// import SidebarSettingIcon from "common/images/sidebar/SidebarSetting-Icon.svg";
import home from "common/images/sidebar/Home.svg";
import homeActive from "common/images/sidebar/HomeActive.svg";

export const ModuleMenu = [
  {
    label: "DASHBOARD",
    url: "",
    icon: SidebarElevadeDashboardIconActive,
  },
  {
    label: "divider",
    isDivider: true,
  },
  {
    label: "FLEET",
    url: process.env.REACT_APP_URL_FLEET + "/home",
    icon: SidebarElevadeFleetIcon,
  },
  {
    label: "PEOPLE",
    url: process.env.REACT_APP_URL_PEOPLE,
    icon: SidebarElevadePeopleIcon,
  },
  // {
  //   label: "LEARNING",
  //   url: process.env.REACT_APP_URL_LEARNING,
  //   icon: SidebarElevadeEducadeIcon,
  // },
  // {
  //   label: "DOCUMENT",
  //   url: process.env.REACT_APP_URL_DOCUMENT,
  //   icon: SidebarElevadeDocumentIcon,
  // },
  {
    label: "ENTITY",
    url: process.env.REACT_APP_URL_ENTITY,
    icon: SidebarEntityIcon,
  },
  // {
  //   label: "divider",
  //   isDivider: true,
  // },
  // {
  //   label: "SETTINGS",
  //   url: process.env.REACT_APP_URL_SETTINGS,
  //   icon: SidebarSettingIcon,
  // },
];

export const DashboardMenu = [
  {
    label: "MAIN",
    visible: true,
    children: [
      {
        label: "Dashboard",
        icon: home,
        activeIcon: homeActive,
        disable: false,
        url: "/main",
        visible: true,
      },
    ],
  },
];
