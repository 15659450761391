// src/redux/reducers/sidebarReducer.js
import { SET_MESSAGE_SESSION, SET_SHOW_MESSAGE_BAR } from "../actions/actionTypes";

const initialState = {
  showMessageBar: true,
  messageSession: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_MESSAGE_BAR:
      return {
        ...state,
        showMessageBar: action.payload,
      };
    case SET_MESSAGE_SESSION:
      return {
        ...state,
        messageSession: { ...action.payload },
      };
    default:
      return state;
  }
};

export default userReducer;
