import React, { useEffect, useState } from "react";
import styles from "./ActionableItems.module.css";
import IconArrowLeft from "common/images/Icon-BreadcrumbArrowLeft.svg";
import { formatDate, makeAPIRequest } from "common/helpers/Helpers";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Helmet } from "react-helmet";

const ActionableItems = () => {
  const [isCountNoti, setIsCountNoti] = useState(0);
  const [isActionableData, setIsActionableData] = useState({});

  async function getActionable() {
    try {
      const result = await makeAPIRequest("post", "api/notification/getByFilter", {
        methods: ["Actionable_Item"],
      });

      setIsCountNoti(result.length);

      result.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return 1;
        }

        if (a.createdAt > b.createdAt) {
          return -1;
        }

        return 0;
      });

      const mappedResult = result.map((item) => ({
        userFullName: item.createdBy,
        cardTitle: item.title,
        action: item.message,
        product: item.module,
        datetime: item.createdAt,
        redirectUrl: item.redirectUrl,
        markAsDone: item.markAsDone,
      }));

      const formattedResult = _.groupBy(mappedResult, (item) => {
        const date = new Date(item.datetime);
        return date.toLocaleString("default", { month: "long", year: "numeric" });
      });

      setIsActionableData(formattedResult);
    } catch (error) {
      console.log("Error while fetching notification: ", error);
    }
  }

  useEffect(() => {
    getActionable();
  }, []);

  const DotRed = (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
      <circle cx="3" cy="3" r="3" fill="#E95048" />
    </svg>
  );

  return (
    <div className={styles.container}>
      <Helmet>
        <title>ELEVADE - Actionable Items</title>
      </Helmet>
      <Link to="/main/" className={styles.breadcrumbs} style={{ textDecoration: "none" }}>
        <img src={IconArrowLeft} alt="icon-arrow-left" height={20} />
        <div className={styles.breadcrumbsTitle}>Actionable Items</div>
        <div className={styles.noti}>{isCountNoti}</div>
      </Link>
      <div className={styles.content}>
        {/* <div className={styles.month}>JANUARY 2023A</div> */}
        {Object.keys(isActionableData).map((item, index) => {
          return (
            <>
              <div className={styles.month}>{item.toLocaleUpperCase()}</div>
              {isActionableData[item].map((item2) => {
                return (
                  <div className={styles.taskCard} key={index}>
                    <div>
                      <span className={styles.bold}>{item2.userFullName ? item2.userFullName : item2.cardTitle}</span>{" "}
                      <span className={styles.cardAction}>{item2.action}</span>
                    </div>
                    <div className={styles.divProduct}>{item2.product}</div>
                    <div className={styles.dateTimeDifference}>{formatDate(item2.datetime)}</div>
                    {item2.markAsDone === false && <div className={styles.dotRed}>{DotRed}</div>}
                  </div>
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ActionableItems;
