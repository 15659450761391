import axios from "axios";
import { COOKIES } from "common/constant";
import Cookies from "js-cookie";

export function calculateDifferenceAndFormat(datetime) {
  // Parse the provided datetime string
  const givenDate = new Date(datetime);

  // Get the current datetime
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = currentDate - givenDate;

  // Convert milliseconds to minutes and round
  const differenceInMinutes = Math.round(Math.abs(differenceInMilliseconds / (1000 * 60)));

  // Decide whether to show the difference in minutes, hours, or days
  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} ${differenceInMinutes === 1 ? "minute" : "minutes"}`;
  } else if (differenceInMinutes < 60 * 24) {
    const differenceInHours = Math.round(differenceInMinutes / 60);
    return `${differenceInHours} ${differenceInHours === 1 ? "hour" : "hours"}`;
  } else {
    const differenceInDays = Math.round(differenceInMinutes / (60 * 24));
    return `${differenceInDays} ${differenceInDays === 1 ? "day" : "days"}`;
  }
}

export function formatNotificationDateTime(dateTimeStr) {
  const inputDate = new Date(dateTimeStr);
  const now = new Date();

  // Create a date string in 'YYYY-MM-DD' format for both input date and current date
  const inputDateStr = inputDate.toISOString().split("T")[0];
  const currentDateStr = now.toISOString().split("T")[0];

  // Check if the input date is the same as the current date
  if (inputDateStr === currentDateStr) {
    // Format as "Today, HH:MM"
    return `Today, ${inputDate.getHours().toString().padStart(2, "0")}:${inputDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  } else {
    // Format as "MMM DD, HH:MM"
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${monthNames[inputDate.getMonth()]} ${inputDate.getDate().toString().padStart(2, "0")}, ${inputDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${inputDate.getMinutes().toString().padStart(2, "0")}`;
  }
}

export const formatDate = (inputDate) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  if (inputDate !== undefined && inputDate !== null && inputDate !== "") {
    if (inputDate.includes("T")) inputDate = inputDate.split("T")[0];
    const dateParts = inputDate?.split("-");
    const year = dateParts[0];
    const month = months[parseInt(dateParts[1]) - 1];
    const day = parseInt(dateParts[2]);

    return `${month} ${day}, ${year}`;
  }

  return "-";
};

const BASE_API_URL = process.env.REACT_APP_ELEVADE_IO_API_URL;
export const makeAPIRequest = async (
  method,
  endPoint,
  data = null,
  isExternalAPI = false,
  extraHeader = {},
  base_url = null,
) => {
  let result = null;
  try {
    if (isExternalAPI) {
      switch (method) {
        case "get":
          result = await axios.get(`${endPoint}`);
          break;
        case "delete":
          result = await axios.delete(`${endPoint}`);
          break;
        case "post":
          result = await axios.post(`${endPoint}`, data);
          break;
        case "put":
          result = await axios.put(`${endPoint}`, data);
          break;
        default:
          result = { message: "Unknow method." };
      }
    } else {
      const reqHeader = {
        headers: {
          "Content-Type": "application/json",
        },
        ...extraHeader,
      };

      const token = Cookies.get(COOKIES.ELEVADE_TOKEN);

      reqHeader.headers.token = `${token}`;

      switch (method) {
        case "get":
          result = await axios.get(`${BASE_API_URL}/${endPoint}`, reqHeader);
          break;
        case "delete":
          result = await axios.delete(`${BASE_API_URL}/${endPoint}`, reqHeader);
          break;
        case "post":
          result = await axios.post(`${BASE_API_URL}/${endPoint}`, data, reqHeader);
          break;
        case "put":
          result = await axios.put(`${BASE_API_URL}/${endPoint}`, data, reqHeader);
          break;
        default:
          result = { message: "Unknown method." };
      }
    }

    return result.data;
  } catch (error) {
    const message = error.response?.data.message;
    if (message !== "Member does not exist") {
      // showNotification("danger", "Error", [message]);
    }
    return { error: true, message };
  }
};

// Function to delete all cookies
export function deleteAllCookies() {
  // Get all cookie names
  const cookieNames = Object.keys(Cookies.get());

  // Iterate through each cookie name and remove it
  cookieNames.forEach(function (name) {
    Cookies.remove(name);
  });
}

export function getEnvironment() {
  switch (process.env.REACT_APP_APPENV) {
    case "staging":
      return "STG";
    case "ppd":
      return "PPD";
    case "prd":
      return "PROD";
    default:
      return "STG";
  }
}
