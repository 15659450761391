import React from "react";
import { Box } from "@mui/material";
import styles from "./Sidebar.module.css";
// import ElevadeSidebar from "./ElevadeSidebar";
import { ElevadeSidebar } from "@ade/global-component-web";
import DashboardSidebar from "./DashboardSidebar";
import { useLocation } from "react-router";
import { getEnvironment } from "common/helpers/Helpers";

const Sidebar = () => {
  const location = useLocation();
  console.log("env: ", getEnvironment());
  return (
    location.pathname !== "/callback" &&
    location.pathname !== "/login" &&
    location.pathname !== "/" && (
      <Box className={styles.container}>
        <ElevadeSidebar
          env={getEnvironment()}
          module="DASHBOARD"
          style={{
            marginTop: "105px",
          }}
        />
        <DashboardSidebar />
      </Box>
    )
  );
};

export default Sidebar;
