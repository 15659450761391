import { useEffect } from "react";
import Cookies from "js-cookie";
import { Grid, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./LandingPage.module.css";
import ElevadeLogo from "common/images/ElevadeLogo-BNW.svg";
import RightArrow from "common/images/Icon-ArrowRight-White.svg";
import CardGroup from "common/images/CardGroup4.png";
import AircraftMonitoring from "common/images/AircraftMonitoringDashboard2.png";
import WarningList from "common/images/WarningList2.svg";
import AircraftLocator from "common/images/AircraftLocator.svg";
import ReliabilityManagement from "common/images/ReliabilityManagement.svg";
import Customer from "common/images/customer.svg";
import Building from "common/images/building.svg";
import Checklist from "common/images/checklist.svg";
import Map from "common/images/map.svg";
import Globe from "common/images/globe.svg";
import Phones from "common/images/phone-group.svg";
import GooglePlay from "common/images/googleplay.svg";
import AppStore from "common/images/appstore.svg";
import FleetLandingPage_DASR from "common/images/FleetLandingPage-DASR.svg";
import FleetLandingPage_CabinMonitoring from "common/images/FleetLandingPage-CabinMonitoring.svg";
import FleetLandingPage_AircraftMonitoringAnalytics from "common/images/FleetLandingPage-AircraftMonitoringAnalytics.svg";
import { COOKIES } from "common/constant";
import { useSelector } from "react-redux";

const LandingPage = () => {
  const token = Cookies.get(COOKIES.ELEVADE_TOKEN);
  const { userDetails } = useSelector((state) => state.user);
  const isMobile = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate();

  const FleetHealthMonitoring = true;
  const PrescriptiveMaintenance = false;
  const AircraftLocator_ = false;
  const FleetManagement = false;
  const ReliabilityManagement_ = false;
  const Numbers = false;
  const DownloadElevade = false;
  const CabinMonitoring = true;
  const DASR = true;
  const AircraftMonitoringAnalytics = true;
  const SubFooter = false;
  const link = "https://calendly.com/elevadefleet/30min";

  const handleOnClick = (link) => {
    navigate(link);
  };

  useEffect(() => {
    if (token && userDetails) {
      navigate("/main", { replace: true });
    }
  }, [token, navigate, userDetails]);

  return (
    <>
      <div id="landing-page-main" className={`${styles.main}`} style={{ marginBottom: "-100px" }}>
        <div
          id="landing-page-content1"
          className={styles.landingPageContent1}
          style={{ backgroundColor: "transparent" }}
        >
          <div className={`${styles.subHeaderTitle}`}>
            <span>MODULE</span>
          </div>
          <Grid columns={2} container>
            <Grid id="content1-left" xs={2} md={1} lg={1} xl={1} style={{ zIndex: "1" }}>
              <div>
                <img src={ElevadeLogo} alt="Elevade Fleet" />
              </div>
              <div style={{ marginTop: "44px" }} className={`${styles.textExtraLarge}`}>
                <div>One stop solution for</div>
                <div className={styles.textGradient}>
                  <div>fleet health monitoring</div>
                  <div>and management</div>{" "}
                </div>
              </div>
              <div className={styles.textSmall} style={{ marginTop: "24px", marginBottom: "24px" }}>
                <span className={styles.textSmallBold}>ELEVADE FLEET</span> helps to manage and monitor{" "}
                <span className={styles.textSmallBold}>fleet health status; managing, analysing</span> and{" "}
                <span className={styles.textSmallBold}>anticipating unexpected problems</span> in real time.
              </div>

              <Grid columns={2} container>
                <Grid item xs={1}>
                  <ul className={`${styles.ul} ${styles.textSmallBold}`}>
                    <li>Fleet Health Monitoring</li>
                    <li>Daily Aircraft Status Report (DASR)</li>
                  </ul>
                </Grid>
                <Grid item xs={1}>
                  <ul className={`${styles.ul} ${styles.textSmallBold}`}>
                    <li>Cabin Monitoring with RFID Technology</li>
                    <li>Aircraft Monitoring Analytics</li>
                  </ul>
                </Grid>
              </Grid>
              <div style={{ display: isMobile ? "block" : "flex" }}>
                <button
                  className={styles.button}
                  onClick={() => {
                    window.location.href = process.env.REACT_APP_URL_FLEET + "/home";
                  }}
                >
                  <span className={styles.textSmallBold} style={{ color: "white", whiteSpace: "nowrap" }}>
                    Get Started Now!
                  </span>
                  <img src={RightArrow} alt="right arrow" />
                </button>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <button className={styles.buttonOutline}>
                    <span className={styles.textSmallButtonOutlined}>Book a Demo</span>
                  </button>
                </a>
              </div>
            </Grid>
            {!isMobile && (
              <Grid id="content1-right" xs={2} lg={1} xl={1} md={1}>
                <img
                  src={CardGroup}
                  alt="Cards"
                  style={{
                    marginLeft: "-50vw",
                    width: "97vw",
                    height: "auto",
                    // overflow: "hidden",
                  }}
                  className={styles.img}
                />
              </Grid>
            )}
          </Grid>
        </div>
        <div id="landing-page-content2" style={{ paddingTop: "50px" }}>
          <div className={`${styles.subHeaderTitle}`}>
            <span>SUBMODULES</span>
          </div>
          {/* ----------- FLEET HEALTH MONITORING ----------  */}
          {FleetHealthMonitoring && (
            <Grid columns={2} container>
              <Grid item xs={2} md={1} lg={0.9} xl={0.9}>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span className={styles.textMediumBold}>Fleet Health Monitoring</span>
                  <span className={styles.newTag}>New</span>
                </div>
                <div className={styles.textSmall} style={{ marginTop: "24px", marginBottom: "24px" }}>
                  <span className={styles.textSmallBold}>Timely monitoring and analysis</span> of fleet health presents
                  operators with a huge advantage in ensuring a smooth operation
                </div>
                <div>
                  <ul className={`${styles.ulArrow} ${styles.textSmall}`}>
                    <li>
                      Using <span className={styles.textSmallBold}>real-time aircraft data</span> to monitor fleet
                      condition regardless the aircraft position
                    </li>
                    <li>
                      <span className={styles.textSmallBold}>Early notification of aircraft defects </span>
                      for better coordination and safety
                    </li>
                    <li>
                      <span className={styles.textSmallBold}>Defect pattern detection</span> via{" "}
                      <span className={styles.textSmallBold}>repetitive event algorithms</span>
                    </li>
                    <li>
                      <span className={styles.textSmallBold}>Centralised decision making</span> made possible by{" "}
                      <span className={styles.textSmallBold}>big data</span>
                    </li>
                  </ul>
                </div>
              </Grid>
              {!isMobile && (
                <Grid item xs={2} md={1} lg={1.1} xl={1.1} mt={{ md: -10, xl: -20, lg: -20 }} sx={{ width: "100vw" }}>
                  <img src={AircraftMonitoring} alt="Aircraft Monitoring Dashboard" className={styles.img} />
                </Grid>
              )}
            </Grid>
          )}

          {/* ----------- CABIN MONITORING ----------  */}
          {CabinMonitoring && (
            <Grid
              container
              columns={2}
              // sx={{ mt: !isMobile ? -3 : 5 }}
              mt={{ xs: 5, sm: 5, md: 7, lg: -3, xl: -3 }}
              mb={{ xs: 5, sm: 5, md: 5, lg: 0, xl: 0 }}
            >
              {!isMobile && (
                <Grid
                  item
                  xs={2}
                  md={1}
                  lg={1.1}
                  xl={1.1}
                  // sx={{
                  //   mt: { lg: -22, xl: -22, md: -12 },
                  //   ml: { lg: -26, xl: -26, md: -20 },
                  // }}
                >
                  <img src={FleetLandingPage_CabinMonitoring} alt="" />
                </Grid>
              )}
              <Grid item md={1} xs={2} lg={0.9} xl={0.9} sx={{ width: "100vw", pl: !isMobile ? 0 : 0 }}>
                <div style={{ width: isMobile ? "100%" : "540px" }}>
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <span className={styles.textMediumBold}>Cabin Monitoring with RFID Technology</span>
                    <span className={styles.newTag}>New</span>
                  </div>
                  <div className={styles.textSmall} style={{ marginTop: "24px", marginBottom: "24px" }}>
                    RFID scanning technology helps airlines improve the accuracy and efficiency of life vest
                    inspections, while ensuring passenger safety
                  </div>
                  <div>
                    <ul className={`${styles.ulArrow} ${styles.textSmall}`}>
                      <li>
                        Easily verify the <span className={styles.textSmallBold}>availability of life vests</span> and
                        their expiry dates.
                      </li>
                      <li>
                        Using RFID tags allows airlines to{" "}
                        <span className={styles.textSmallBold}>
                          effortlessly track the location and availability of life vests.
                        </span>
                      </li>
                      <li>
                        RFID tags{" "}
                        <span className={styles.textSmallBold}>the risk of theft and missing life vests.</span>
                      </li>
                      <li>
                        RFID scanning helps ensure the availability of life vests,{" "}
                        <span className={styles.textSmallBold}>
                          ready for passenger use in the event of an emergency.
                        </span>
                      </li>
                    </ul>
                    <button className={styles.button} onClick={() => handleOnClick("/cabin-monitoring")}>
                      <span className={styles.textSmallBold} style={{ color: "white" }}>
                        Learn More
                      </span>
                      <img src={RightArrow} alt="right arrow" />
                    </button>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          {/* ----------- DASR ----------  */}
          {DASR && (
            <Grid columns={2} container mt={{ xs: 0, sm: 0, md: 5, lg: 13, xl: 13 }}>
              <Grid item xs={2} md={1} lg={0.9} xl={0.9} sx={{ marginTop: !isMobile ? "4px" : 1 }}>
                <div style={{ width: isMobile ? "100%" : "540px" }}>
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <span className={styles.textMediumBold}>Daily Aircraft Status Report (DASR)</span>
                    <span className={styles.newTag}>New</span>
                  </div>
                  <div className={styles.textSmall} style={{ marginTop: "24px", marginBottom: "24px" }}>
                    Daily Aircraft Status Report (DASR) plays a crucial role for airlines in{" "}
                    <span className={styles.textSmallBold}>monitoring and managing aircrafts’ daily status</span>
                  </div>
                  <div>
                    <ul className={`${styles.ulArrow} ${styles.textSmall}`}>
                      <li>
                        <span className={styles.textSmallBold}>Immediate aircraft status updates </span>for maintenance
                        and operational teams
                      </li>
                      <li>
                        <span className={styles.textSmallBold}>Centralised data repository </span>that simplifies access
                        and allows for trend analysis
                      </li>
                      <li>
                        Enhanced <span className={styles.textSmallBold}>precision in reporting and monitoring </span>of
                        DDMLs via systems integration
                      </li>
                      <li>
                        <span className={styles.textSmallBold}>Strengthen communication between </span>the
                        airline&rsquo;s maintenance team and the operation control team
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              {!isMobile && (
                <Grid
                  item
                  xs={2}
                  md={1}
                  lg={1.1}
                  xl={1.1}
                  // sx={{
                  //   mt: { lg: 0, xl: 0, md: 0 },
                  //   width: "100vw",
                  // }}
                >
                  <img src={FleetLandingPage_DASR} alt="Aircraft Locator" />
                </Grid>
              )}
            </Grid>
          )}
          {/* ----------- AIRCRAFT MONITORING ANALYTICS ----------  */}
          {AircraftMonitoringAnalytics && (
            <Grid
              container
              columns={2}
              // sx={{ mt: !isMobile ? -3 : 5 }}
              mt={{ xs: 5, sm: 5, md: 7, lg: 13, xl: 13 }}
              mb={{ xs: 5, sm: 5, md: 7, lg: 10, xl: 10 }}
            >
              {!isMobile && (
                <Grid
                  item
                  xs={2}
                  md={1}
                  lg={1.1}
                  xl={1.1}
                  // sx={{
                  //   mt: { lg: -22, xl: -22, md: -12 },
                  //   ml: { lg: -26, xl: -26, md: -20 },
                  // }}
                >
                  <img src={FleetLandingPage_AircraftMonitoringAnalytics} alt="" style={{ marginLeft: "-20px" }} />
                </Grid>
              )}
              <Grid
                item
                md={1}
                xs={2}
                lg={0.9}
                xl={0.9}
                sx={{ width: "100vw", pl: !isMobile ? 0 : 0, border: "0px solid red" }}
              >
                <div style={{ width: isMobile ? "100%" : "540px" }}>
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <span className={styles.textMediumBold}>Aircraft Monitoring Analytics</span>
                    <span className={styles.newTag}>New</span>
                  </div>
                  <div className={styles.textSmall} style={{ marginTop: "24px", marginBottom: "24px" }}>
                    Aircraft Monitoring Analytics examine the data from both historical and present aircraft performance
                  </div>
                  <div>
                    <ul className={`${styles.ulArrow} ${styles.textSmall}`}>
                      <li>
                        Using visual elements to represent complex data makes it simpler for users to understand{" "}
                        <span className={styles.textSmallBold}>important insights and trends</span>
                      </li>
                      <li>
                        <span className={styles.textSmallBold}>Identifying recurring patterns </span>
                        in Top ATA Repetitive and ECAM Messages
                      </li>
                      <li>
                        <span className={styles.textSmallBold}>Receive real-time alerts </span>
                        for ECAM messages indicating NO GO conditions
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          {/* ----------- PRESCRIPTIVE MAINTENANCE ----------  */}
          {PrescriptiveMaintenance && (
            <Grid
              container
              columns={2}
              // sx={{ mt: !isMobile ? -3 : 5 }}
              mt={{ xs: 5, sm: 5, md: 7, lg: -3, xl: -3 }}
            >
              {!isMobile && (
                <Grid
                  item
                  xs={2}
                  md={1}
                  lg={1.1}
                  xl={1.1}
                  sx={{
                    mt: { lg: -22, xl: -22, md: -12 },
                    ml: { lg: -26, xl: -26, md: -20 },
                  }}
                >
                  <img src={WarningList} alt="" className={styles.img} />
                </Grid>
              )}
              <Grid item md={1} xs={2} lg={0.9} xl={0.9} sx={{ width: "100vw", ml: !isMobile ? 20 : 0 }}>
                <div>
                  <span className={styles.textMediumBold}>Prescriptive Maintenance</span>
                </div>
                <div className={styles.textSmall} style={{ marginTop: "24px", marginBottom: "24px" }}>
                  Provides <span className={styles.textSmallBold}> in-depth analysis</span> of system and{" "}
                  <span className={styles.textSmallBold}>component performance and anticipates surprises</span> before
                  imminent failures
                </div>
                <div>
                  <ul className={`${styles.ulArrow} ${styles.textSmall}`}>
                    <li>
                      <span className={styles.textSmallBold}>Minimize surprises in unexpected </span>
                      breakdowns{" "}
                    </li>
                    <li>
                      <span className={styles.textSmallBold}>Maximize asset lifespan and reliability</span>{" "}
                    </li>
                    <li>
                      <span className={styles.textSmallBold}>Reduce operational costs</span> by anticipating delays
                    </li>
                    <li>
                      <span className={styles.textSmallBold}>Improve safety</span> with timely response to impending
                      failures
                    </li>
                    <li>
                      Streamline cost management through{" "}
                      <span className={styles.textSmallBold}>optimised resource utilisation</span>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          )}
          {/* ----------- AIRCRAFT LOCATOR ----------  */}
          {AircraftLocator_ && (
            <Grid columns={2} container mt={{ xs: 0, sm: 0, md: -5, lg: -15, xl: -15 }}>
              <Grid item xs={2} md={1} lg={0.9} xl={0.9} sx={{ marginTop: !isMobile ? "80px" : 5 }}>
                <div>
                  <span className={styles.textMediumBold}>Aircraft Locator</span>
                </div>
                <div className={styles.textSmall} style={{ marginTop: "24px", marginBottom: "24px" }}>
                  Real-time* aircraft locator for <span className={styles.textSmallBold}>better visualisation</span> of
                  the fleet condition{" "}
                </div>
                <div>
                  <ul className={`${styles.ulArrow} ${styles.textSmall}`}>
                    <li>
                      <span className={styles.textSmallBold}>Monitor your fleet condition</span> and{" "}
                      <span className={styles.textSmallBold}>track your asset status</span>{" "}
                    </li>
                    <li>
                      <span className={styles.textSmallBold}>Detect and reduce</span> major faults and consequential
                      costs{" "}
                    </li>
                    <li>
                      <span className={styles.textSmallBold}>Enhance visibility</span> of aircraft position and failures{" "}
                    </li>
                  </ul>
                </div>
              </Grid>
              {!isMobile && (
                <Grid
                  item
                  xs={2}
                  md={1}
                  lg={1.1}
                  xl={1.1}
                  sx={{
                    mt: { lg: -7, xl: -7, md: 0 },
                    width: "100vw",
                  }}
                >
                  <img src={AircraftLocator} alt="Aircraft Locator" className={styles.img} />
                </Grid>
              )}
            </Grid>
          )}
          {/* ----------- FLEET MANAGEMENT ----------  */}
          {FleetManagement && (
            <Grid
              container
              columns={2}
              // sx={{ marginTop: "40px" }}
              mt={{ xs: 0, sm: 0, md: 0, lg: -5, xl: -5 }}
            >
              {!isMobile && (
                <Grid item xs={2} lg={1.1} xl={1.1} md={1} sx={{ mt: { md: 6 } }}>
                  <img src={DASR} alt="" style={{ marginLeft: "-40px" }} className={styles.img} />
                </Grid>
              )}
              <Grid item xs={2} lg={0.9} md={1} xl={0.9} sx={{ width: "100vw", marginTop: "50px" }}>
                <div>
                  <span className={styles.textMediumBold}>FLEET MANAGEMENT</span>
                </div>
                <div className={styles.textSmall} style={{ marginTop: "24px", marginBottom: "24px" }}>
                  Comprehensive fleet information for an <span>end-to-end aircraft life-cycle management</span>
                </div>
                <div>
                  <ul className={`${styles.ulArrow} ${styles.textSmall}`}>
                    <li>
                      Overview of <span className={styles.textSmallBold}>aircraft airworthiness</span>
                    </li>
                    <li>
                      <span className={styles.textSmallBold}> Utilisation tracking</span> of major components
                    </li>
                    <li>
                      Status of <span className={styles.textSmallBold}>aircraft configurations and approvals</span>
                    </li>
                    <li>
                      Summary of <span className={styles.textSmallBold}>events and activities performed</span> on an
                      aircraft
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          )}
          {/* ----------- RELIABILITY MANAGEMENT ----------  */}
          {ReliabilityManagement_ && (
            <Grid
              columns={2}
              container
              // sx={{ marginTop: "40px" }}
              mt={{ xs: 5, sm: 5, md: 5, lg: 2, xl: 2 }}
            >
              <Grid item xs={2} md={1} lg={0.9} xl={0.9}>
                <div>
                  <span className={styles.textMediumBold}>Reliability Management</span>
                </div>
                <div className={styles.textSmall} style={{ marginTop: "24px", marginBottom: "24px" }}>
                  Extensive fleet reliability management for a{" "}
                  <span className={styles.textSmallBold}>holistic view</span> of the{" "}
                  <span className={styles.textSmallBold}>fleet and component status</span>
                </div>
                <div>
                  <ul className={`${styles.ulArrow} ${styles.textSmall}`}>
                    <li>
                      <span className={styles.textSmallBold}>Monitor fleet condition</span> and{" "}
                      <span className={styles.textSmallBold}>tracks defect pattern</span>
                    </li>
                    <li>
                      <span className={styles.textSmallBold}>Perform in-depth root cause analysis</span> on the top
                      operational interruptions (OI) contributors
                    </li>
                    <li>
                      <span className={styles.textSmallBold}>Automate reliability reporting</span> to the relevant
                      authorities
                    </li>
                  </ul>
                </div>
              </Grid>
              {!isMobile && (
                <Grid item xs={2} md={1} lg={1.1} xl={1.1} sx={{ width: "100vw" }}>
                  <img src={ReliabilityManagement} alt="Reliability Management" className={styles.img} />
                </Grid>
              )}
            </Grid>
          )}
        </div>
        {Numbers && (
          <Grid
            id="landing-page-content3"
            style={{
              maxWidth: "100%",
              background: "#F7FCFE",
              height: "557px",
              display: "flex",
              flexDirection: "column", // Stack children vertically
              alignItems: "center", // Center horizontally
              justifyContent: "center", // Center vertically
            }}
            mt={{ xs: 10, sm: 10, md: 5, lg: 5, xl: 5 }}
          >
            <div id="subcontent" style={{ textAlign: "center", width: "100%" }}>
              <center className={styles.subHeaderTitle}>NUMBERS DON&rsquo;T LIE</center>
              <center className={styles.textMediumBold}>Teams of various sizes have begun utilising ELEVADE</center>
              <center className={styles.textSmall} style={{ color: "#394458" }}>
                These numbers continue to rise to demonstrate our expertise and ability to assist with the growth of
                your organisation.
              </center>

              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  my: 5,
                }}
                spacing={2}
              >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Grid container columns={2}>
                    <Grid item xs={1}>
                      <img src={Customer} alt="Customer" className={`${styles.icon} `} />
                    </Grid>
                    <Grid item xs={1}>
                      <Grid className={styles.numbers}>3,500+</Grid>
                      <Grid className={styles.textSmall}>Users</Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Grid>
                    <Grid container columns={2}>
                      <Grid item xs={1}>
                        <img src={Building} alt="Building" className={`${styles.icon} `} />
                      </Grid>
                      <Grid item xs={1}>
                        <Grid className={styles.numbers}>100+</Grid>
                        <Grid className={styles.textSmall}>Companies Enrolled</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Grid>
                    <Grid container columns={2}>
                      <Grid item xs={1}>
                        <img src={Checklist} alt="Checklist" className={`${styles.icon} `} />
                      </Grid>
                      <Grid item xs={1}>
                        <Grid className={styles.numbers}>10,000+</Grid>
                        <Grid className={styles.textSmall}>Tasks Performed</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Grid>
                    <Grid container columns={2}>
                      <Grid item xs={1}>
                        <img src={Map} alt="Map" className={`${styles.icon} `} />
                      </Grid>
                      <Grid item xs={1}>
                        <Grid className={styles.numbers}>24</Grid>
                        <Grid className={styles.textSmall}>Locations Covered</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Grid>
                    <Grid container columns={2}>
                      <Grid item xs={1}>
                        <img src={Globe} alt="Globe" className={`${styles.icon} `} />
                      </Grid>
                      <Grid item xs={1}>
                        <Grid className={styles.numbers}>15</Grid>
                        <Grid className={styles.textSmall}>Different Countries</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Grid>
                    <Grid container columns={2}>
                      <Grid item xs={1}>
                        <img src={Customer} alt="Customer" className={`${styles.icon} `} />
                      </Grid>
                      <Grid item xs={1}>
                        <Grid className={styles.numbers}>2,000+</Grid>
                        <Grid className={styles.textSmall}>Aircrafts Serviced</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <center style={{ marginTop: "75px" }}>
                <button className={styles.button}>
                  <span className={styles.textSmall} style={{ color: "white" }}>
                    Get Started Now!
                  </span>
                  <img src={RightArrow} alt="right arrow" />
                </button>
              </center>
            </div>
          </Grid>
        )}
        {DownloadElevade && (
          <Grid id="landing-page-content-4" className={styles.landingPageContent4}>
            <Grid container columns={2} sx={{ mt: { sm: 2, xs: 2, lg: 0, xl: 0, md: 0 } }}>
              <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column", // Stack children vertically
                    alignItems: {
                      // Center horizontally
                      lg: "start",
                      xl: "start",
                      md: "start",
                      xs: "center",
                      sm: "center",
                    },
                    justifyContent: "center", // Center vertically
                    height: "100%",
                    marginLeft: { lg: 10, md: 10, xl: 10, sm: 10, xs: 10 },
                    py: { sm: 5, xs: 5, lg: 0, md: 0, xl: 0 },
                  }}
                >
                  <div style={{ width: "100%", gap: "32px" }}>
                    <div>
                      <span className={styles.textLarge}>DOWNLOAD ELEVADE NOW!</span>
                    </div>
                    <Grid
                      sx={{
                        py: "32px",
                        pr: { xs: "45px", sm: "45px" },
                      }}
                    >
                      <span className={styles.textSmall} style={{ color: "#fff" }}>
                        It&rsquo;s all at your fingertips – Elevate your success today – Experience the future of
                        productivity with ELEVADE.
                      </span>
                    </Grid>
                    <Grid
                      className={styles.row}
                      sx={{
                        gap: "22px",
                        ml: { xs: 20, sm: 20, md: 0, xl: 0, lg: 0 },
                      }}
                    >
                      <img src={AppStore} alt="AppStore" />
                      <img src={GooglePlay} alt="GooglePlay" />
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              {!isMobile && (
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={1}
                  lg={1}
                  xl={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column", // Stack children vertically
                    alignItems: "center", // Center horizontally
                    justifyContent: "center", // Center vertically
                  }}
                >
                  <img src={Phones} alt="Mobile Group" style={{ marginTop: "10px" }} />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {SubFooter && (
          <div>
            <div id="subfooter" className={styles.footer} style={{ borderBottom: "0px solid #F1F1F1" }}>
              <Grid container spacing={!isMobile ? 15 : 5}>
                <Grid item sx={{ ml: 5 }}>
                  <span className={styles.textSmallBold} style={{ color: "#fff" }}>
                    Solutions
                  </span>
                  <ul className={`${styles.textSmall} ${styles.ulFooter}`} style={{ color: "#fff" }}>
                    <li>PEOPLE</li>
                    <li>FLEET</li>
                    <li>LEARNING</li>
                    <li>DOCUMENTS</li>
                    <li>AEROTRADE</li>
                  </ul>
                </Grid>
                <Grid item>
                  <span className={styles.textSmallBold} style={{ color: "#fff" }}>
                    Resources
                  </span>
                  <ul className={`${styles.textSmall} ${styles.ulFooter}`} style={{ color: "#fff" }}>
                    <li>Downloads</li>
                    <li>Help Docs</li>
                    <li>FAQ</li>
                    <li>Contact Us</li>
                  </ul>
                </Grid>
                <Grid item>
                  <span className={styles.textSmallBold} style={{ color: "#fff" }}>
                    Pricing
                  </span>
                  <ul className={`${styles.textSmall} ${styles.ulFooter}`} style={{ color: "#fff" }}>
                    <li>Plans</li>
                  </ul>
                </Grid>
                <Grid item>
                  <span className={styles.textSmallBold} style={{ color: "#fff" }}>
                    Company
                  </span>
                  <ul className={`${styles.textSmall} ${styles.ulFooter}`} style={{ color: "#fff" }}>
                    <li>About Us</li>
                    <li>Investor Relations</li>
                    <li>Contact Us</li>
                    <li>News</li>
                  </ul>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                borderTop: "1px solid #ffffff",
                position: "absolute",
                bottom: 0,
                width: "100%",
                opacity: "0.2",
              }}
            ></div>
          </div>
        )}
      </div>
    </>
  );
};
export default LandingPage;
