import React, { useCallback, useEffect, useRef, useState } from "react";
import { COOKIES, ROUTES } from "common/constant";
import { makeAPIRequest, deleteAllCookies } from "common/helpers/Helpers";
import styles from "./Header.module.css";
import IconHamburger from "common/images/Icon-Hamburger.svg";
import LogoElevadeMain from "common/images/Logo-ElevadeMain.svg";
import IconBell from "common/images/Icon-Bell.svg";
import { Grid, Button, Fade, styled, ClickAwayListener } from "@mui/material";
import MUIAvatar from "common/components/avatar/MUIAvatar";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/actions/sidebarActions";
import DialogBox from "common/components/dialog/DialogBox";
import { useLocation } from "react-router";
import IconLogout from "common/images/Icon-Logout.svg";
import { logout } from "common/redux/actions/userAction";
import Cookies from "js-cookie";
import axios from "axios";
import _ from "lodash";
import { Link, NavLink } from "react-router-dom";
import { setMessageSession } from "common/redux/actions/userSessionAction";
import { setAuthToken } from "common/redux/actions/authToken";

import IconArrowUpGreen from "common/images/Icon-ArrowUpGreen.svg";
import Airplane from "common/images/Icon-Airplane-Tilt-Green.svg";
import ContactUs from "common/images/Icon-ContactUs.svg";
import People from "common/images/Icon-ElevadePeople.svg";
import Cart from "common/images/Icon-Cart.svg";
import IconADE from "common/images/Icon-ADE.svg";

const StyledButton = styled(Button)(() => ({
  // outline: "none",
  // padding: "11px 24px",
  // backgroundColor: "#088FD1",
  fontFamily: "Gotham-Book",
  borderRadius: "8px",
  borderColor: "#088FD1",
  textTransform: "none",
  color: "#333",
  ":hover": {
    background: "#088FD1",
    color: "#fff",
  },
  width: "130px",
  height: "44px",
  // "&&:focus": {
  //   outline: "none",
  // },
  // boxShadow: "none",
  // border: "0px solid #D1D1D1",
  // marginTop: "-4px",
  // whiteSpace: "nowrap",
}));

const Header = () => {
  const email = "mailto:elevade_support@ade.aero";

  const userProfile = useSelector((state) => state.user.userProfile);
  const userDetails = useSelector((state) => state.user.userDetails);
  // const token = useSelector((state) => state.token.token);

  const [isCountNoti, setIsCountNoti] = useState(0);
  const dispatch = useDispatch();
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [isToken, setIsToken] = useState("");
  const [openSolutions, setOpenSolutions] = useState(false);
  const [showSolutionsMenu, setShowSolutionsMenu] = useState(false);

  const token = Cookies.get(COOKIES.ELEVADE_TOKEN);
  const baseUrl = process.env.REACT_APP_AUTH_GS_AUTHENTICATION_API_URL;

  useEffect(() => {
    setIsToken(token);
    // if (!_.isEmpty(token)) verifyToken(token);
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (!_.isEmpty(isToken)) verifyToken(isToken);
    // eslint-disable-next-line
  }, [isToken]);

  async function getNotification() {
    try {
      const result = await makeAPIRequest("post", "api/notification/getByFilter", {});
      setIsCountNoti(result.filter((item) => !item?.read).length);
    } catch (error) {
      console.log("Error while fetching notification: ", error);
      setIsCountNoti(0);
    }
  }

  useEffect(() => {
    getNotification();
  }, [isToken]);

  // Verify Token with AUTH GS
  async function verifyToken(token) {
    try {
      await axios
        .post(`${baseUrl}/api/v1/token/verify`, { token: token })
        .then((response) => {
          // Set new token
          Cookies.set(COOKIES.ELEVADE_TOKEN, response.data.tokens.token, {
            expires: 1,
          });
        })
        .catch((error) => {
          console.log("error:", error);
          Cookies.remove(COOKIES.ELEVADE_TOKEN);
          deleteAllCookies();
          dispatch(logout());
          // window.location.replace("/login");
        });
    } catch (errors) {
      console.log(errors);
    }
  }

  const MyAccount = useCallback(() => {
    return (
      showMenu && (
        <ClickAwayListener onClickAway={() => setShowMenu(false)}>
          <div className={styles.myAccount}>
            <Grid container columns={1}>
              <Grid item xs={1} className={`${styles.flexCenter} ${styles.pointer}`} onClick={handleLogout}>
                <div className={styles.logoLogout}>
                  <img src={IconLogout} alt="logout" />
                </div>
                <div className={styles.MyOtherProductsText}>Logout</div>
              </Grid>
            </Grid>
          </div>
        </ClickAwayListener>
      )
    );
    // eslint-disable-next-line
  }, [showMenu]);

  const handleLogout = async () => {
    try {
      window.location.replace(ROUTES.LOGOUT);
    } catch (error) {
      console.info("Error during logout", error);
    }
  };

  function handleClickAway() {
    setOpenSolutions(false);
  }

  const Resources = () => {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Fade in={openSolutions}>
          <div
            style={{
              position: "fixed",
              top: "60px",
              left: "0px",
              width: "100%",
              backgroundColor: "#ffffff",
              boxShadow: "0px 8px 19px 0px rgba(0, 0, 0, 0.20)",
            }}
            className={styles.solutionsMenu}
          >
            <>
              <Grid container columns={2} sx={{ height: "100%", padding: "0px 24px" }}>
                <Grid item xs={1.1} sx={{ padding: "24px 0px" }}>
                  <span className={styles.modalHeader}>MODULES</span>
                  <Grid container columns={3}>
                    <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                      <a
                        href="https://airasia.elevade.io/fleet"
                        target="_blank"
                        onClick={() => {
                          handleClickAway();
                        }}
                        rel="noopener noreferrer" // Recommended for security
                      >
                        <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3}>
                            <img src={Airplane} alt="Airplane" />
                          </Grid>
                          <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7}>
                            <Grid className={styles.title}>FLEET</Grid>
                            <Grid className={styles.textSmall}>Aircraft monitoring and predictive maintenance</Grid>
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                      <a
                        href="https://airasia.elevade.io/people"
                        target="_blank"
                        onClick={() => {
                          handleClickAway();
                        }}
                        rel="noopener noreferrer" // Recommended for security
                      >
                        <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3}>
                            <img src={People} alt="People" />
                          </Grid>
                          <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7}>
                            <Grid className={styles.title}>PEOPLE</Grid>
                            <Grid className={styles.textSmall}>People & productivity management</Grid>
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                    {/* <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                    <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3} className={styles.disable}>
                        <img src={Document} alt="Document" />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7} className={styles.disable}>
                        <Grid className={styles.title}>DOCUMENT</Grid>
                        <Grid className={styles.textSmall}>Aircraft digital technical records and documentation</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                    <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3} className={styles.disable}>
                        <img src={Learning} alt="Learning" />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7} className={styles.disable}>
                        <Grid className={styles.title}>LEARNING</Grid>
                        <Grid className={styles.textSmall}>E-Learning and content creation platform</Grid>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={0.9}
                  sx={{
                    borderLeft: "1px solid #F1F1F1",
                    height: "100%",
                    padding: "24px 0px 24px 84px",
                  }}
                >
                  <span className={styles.modalHeader}>OTHER PRODUCTS/LINKS</span>
                  <Grid container columns={2}>
                    <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                      <a
                        href="https://ade.aero/aerotrade/marketplace"
                        target="_blank"
                        onClick={() => {
                          handleClickAway();
                          // window.location.href = "https://ade.aero/aerotrade/marketplace";
                        }}
                        // target="_blank"
                        rel="noopener noreferrer" // Recommended for security
                      >
                        <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3}>
                            <img src={Cart} alt="Cart" />
                          </Grid>
                          <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7}>
                            <Grid className={styles.title}>AEROTRADE</Grid>
                            <Grid className={styles.textSmall}>B2B aviation marketplace</Grid>
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                      <a
                        href="https://ade.aero"
                        target="_blank"
                        onClick={() => {
                          handleClickAway();
                          // window.location.href = "https://ade.aero";
                        }}
                        // target="_blank"
                        rel="noopener noreferrer" // Recommended for security
                      >
                        <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3}>
                            <img src={IconADE} alt="People" />
                          </Grid>
                          <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7}>
                            <Grid className={styles.title}>ADE.AERO</Grid>
                            <Grid className={styles.textSmall}>ADE corporate website</Grid>
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                    {/* <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                    <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3} className={styles.disable}>
                        <img src={Document} alt="Document" />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7} className={styles.disable}>
                        <Grid className={styles.title}>BOOST</Grid>
                        <Grid className={styles.textSmall}>Organization financial application tracking</Grid>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                columns={12}
                sx={{
                  borderTop: "1px solid #F1F1F1",
                  pb: 3,
                  // pt: 2,
                  mt: -1.5,
                  display: "flex",
                  alignSelf: "start",
                  px: 2,
                }}
              >
                <Grid item xs={0.4} lg={0.13} sx={{ mt: 2 }}>
                  <img src={ContactUs} alt="ContactUs" />
                </Grid>
                <Grid item sx={{ mt: 2, pl: 3.6 }}>
                  <Grid className={styles.title}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href = email;
                        e.preventDefault();
                      }}
                      style={{ color: "#394458" }}
                    >
                      Contact Us
                    </Link>
                  </Grid>
                  <Link
                    to="#"
                    onClick={(e) => {
                      window.location.href = email;
                      e.preventDefault();
                    }}
                    style={{ color: "#394458" }}
                  >
                    <Grid className={styles.textSmall}>Get in touch with our sales team for any enquiries</Grid>
                  </Link>
                </Grid>
              </Grid>
            </>
          </div>
        </Fade>
      </ClickAwayListener>
    );
  };

  return (
    location.pathname !== "/callback" &&
    location.pathname !== "/login" && (
      <div className={styles.container}>
        <Grid container columns={12}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={styles.logo}>
            {token && (
              <img
                src={IconHamburger}
                alt="icon-hamburger"
                height={24}
                className={styles.imgLogo}
                onClick={() => dispatch(toggleSidebar())}
              />
            )}
            <div className={styles.logoMain}>
              <Link to={"/main"}>
                <img src={LogoElevadeMain} alt="logo-elevade-main" />
              </Link>
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={styles.user}>
            {!token && (
              <div
                className={styles.solutionsMenuContainer}
                onClick={() => {
                  setOpenSolutions(!openSolutions);
                  setShowSolutionsMenu(!showSolutionsMenu);
                  setShowMenu(false);
                }}
                style={{
                  fontSize: 14,
                  color: "#394458",
                }}
              >
                Solutions
                <img
                  src={IconArrowUpGreen}
                  alt="icon-arrow-up"
                  height={12}
                  style={{
                    transform: openSolutions ? "" : "rotate(180deg)",
                    marginLeft: "8px",
                    marginTop: openSolutions ? "0px" : "4px",
                  }}
                />
              </div>
            )}

            <Resources />

            {!token && (
              <Button
                variant="outlined"
                onClick={() => {
                  window.location.href = email;
                }}
                sx={{
                  fontFamily: "Gotham-Book",
                  borderRadius: "8px",
                  borderColor: "#088FD1",
                  textTransform: "none",
                  color: "#fff",
                  background: "linear-gradient(to left, #08D1AF, #088FD1)",
                  ":hover": {
                    border: "none",
                  },
                  border: "none",
                  minWidth: "130px",
                  height: "44px",
                  marginRight: "24px",
                }}
              >
                Talk to Us!
              </Button>
            )}

            {token && (
              <div className={styles.bell}>
                <div className={styles.divBell} onClick={() => setIsOpenNotification(!isOpenNotification)}>
                  <img src={IconBell} alt="icon-bell" height={22} />
                  {isCountNoti > 0 && <div className={styles.noti}>{isCountNoti > 99 ? "+99" : isCountNoti}</div>}
                </div>
                <DialogBox open={isOpenNotification} setOpen={setIsOpenNotification} setIsCountNoti={setIsCountNoti} />
              </div>
            )}

            {token ? (
              <span onClick={() => setShowMenu(!showMenu)} className={styles.pointer}>
                <MUIAvatar
                  name={userProfile?.fullName}
                  width="40px"
                  height="40px"
                  fontSize="16px"
                  src={userProfile?.avatar}
                />
              </span>
            ) : (
              <div className={styles.buttonLogin}>
                <StyledButton
                  variant="outlined"
                  // endIcon={openLogin ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
                  onClick={() => {
                    window.location.href = process.env.REACT_APP_AUTHGS_URL;
                  }}
                >
                  Log In
                </StyledButton>
              </div>
            )}

            <MyAccount />
          </Grid>
        </Grid>
      </div>
    )
  );
};

export default Header;
